// utils
@import '../styles/variables.scss';

$fontSizes: 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 3.5;
$sizes: 0.25, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 5;

@each $size in $sizes {
    $i: index($sizes, $size);
    .mb-#{$i} {
        margin-bottom: #{$size}rem !important;
    }
}

@each $size in $sizes {
    $i: index($sizes, $size);
    @media (max-width: $tablet) {
        .mb-#{$i}-m {
            margin-bottom: #{$size}rem !important;
        }
    }
}

@each $size in $sizes {
    $i: index($sizes, $size);
    .mt-#{$i} {
        margin-top: #{$size}rem !important;
    }
}

@each $size in $sizes {
    $i: index($sizes, $size);
    @media (max-width: $tablet) {
        .mt-#{$i}-m {
            margin-top: #{$size}rem !important;
        }
    }
}

@each $size in $fontSizes {
    $i: index($fontSizes, $size);
    .fz-#{$i} {
        font-size: #{$size}rem !important;
    }
}

.mb-0 {
    margin-bottom: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.ovh {
    overflow: hidden !important;
}

.text--primary {
    color: var(--primary);
}

.text--light {
    color: var(--purple-light);
}

.text--white {
    color: var(--purple-white);
}

.text--bold {
    font-weight: 700;
}

.text--left {
    text-align: left;
}

.text--right {
    text-align: right;
}

.text--center {
    text-align: center;
}

.text--underline {
    text-decoration: underline;
}

.flex--col {
    display: flex;
    flex-direction: column;
}

.flex--row {
    display: flex;
    flex-direction: row;
}

.flex--start {
    display: flex;
    justify-content: flex-start;
}

.flex--end {
    display: flex;
    justify-content: flex-end;
}

@media (max-width: $tablet) {
    .mt-0-m {
        margin-top: 0 !important;
    }

    .mb-0-m {
        margin-bottom: 0 !important;
    }
}

.w-100 {
    width: 100%;
}
