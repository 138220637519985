@import '../../styles/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $tablet) {
        margin: 2rem 0;
    }
}