.wrapper {
    margin-top: 0;
    counter-reset: list;
    line-height: 1.75;
}

.item {
    display: flex;
    flex-direction: row;
    counter-increment: list;
    list-style: none;
    text-align: left;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &:before {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 0.75rem;
        height: 0.75rem;
        margin-right: 1rem;
        border-radius: 0.75rem;
        transform: translateY(0.55rem);
        background-color: var(--primary);
        content: '';
    }
}
