@import '../../styles/variables';

.wrapper {
    display: inline-grid;
    // gap: 1rem;
    justify-content: center;
    width: 100%;
    margin: -0.5rem;
    margin-left: 0;

    & > * {
        margin: 0.5rem;
    }

    // @media (min-width: $tablet) {
    //     row-gap: 2rem;
    // }

    &.cols-1 {
        grid-template-columns: 1fr;
    }

    &.cols-1-2 {
        grid-template-columns: 1fr 1fr;

        @media (min-width: $tablet) {
            grid-template-columns: 1fr 2fr;
        }
    }

    &.cols-2 {
        grid-template-columns: 1fr;
        &.nonResponsive {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media (min-width: $tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    &.cols-3 {
        grid-template-columns: 1fr;
        &.nonResponsive {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @media (min-width: $tablet) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    &.cols-4 {
        grid-template-columns: 1fr;
        &.nonResponsive {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        @media (min-width: $tablet) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    &.cols-1,
    &.cols-2,
    &.cols-3,
    &.cols-4,
    &.cols-1-2 {
        margin: 0;
    }

    &.cols-auto {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &.inline {
        display: inline-grid;
    }

    &.no-gap {
        gap: 0 !important;
    }
}
