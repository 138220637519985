@keyframes loading {
    0% {
        top: 10%;
        height: 80%;
    }
    50%,
    100% {
        top: 30%;
        height: 40%;
    }
}

.wrapper {
    display: inline-block;
    position: relative;

    &.purple {
        color: var(--primary);
    }

    &.white {
        color: var(--white);
    }

    div {
        display: inline-block;
        position: absolute;
        left: 10%;
        width: 20%;
        background: currentColor;
        animation: loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }
    div:nth-child(1) {
        left: 10%;
        animation-delay: -0.24s;
    }
    div:nth-child(2) {
        left: 40%;
        animation-delay: -0.12s;
    }
    div:nth-child(3) {
        left: 70%;
        animation-delay: 0;
    }
}
