@import '../../styles/variables.scss';

$breakpoint: 30rem;

.wrapper {
    .toggleForm {
        display: flex;
        justify-content: center;
        cursor: pointer;
        color: var(--purple-medium2);

        svg {
            flex-shrink: 0;
            width: 1.625rem;
            height: 1.625rem;
            margin-right: 1rem;
        }

        .text {
            align-items: center;
            display: flex;
            text-decoration: underline;
            text-align: left;
            line-height: 1.625;
        }

        &:hover .text {
            text-decoration: none;
        }
    }

    .form {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        max-width: $breakpoint;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        @media (min-width: $breakpoint) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .input {
        width: 100%;
    }

    .message {
        max-width: 100%;
        width: 100%;
        min-height: 9rem;
        resize: none;
    }

    .messageLabel {
        background-color: white;
        padding: 0 2px 2px 0;
        border-radius: 0 0 5px 5px;
    }

    .input,
    .message {
        display: block;
        padding: 1rem 1.5rem;
        border: 0;
        border-radius: 1.5rem;
        background-color: var(--white);

        &::placeholder {
            color: var(--gray-medium);
        }

        &:focus::placeholder {
            opacity: 0;
        }

        &:focus + label,
        &:not(:placeholder-shown) + label {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .message {
        padding-top: 18px;
    }

    .button {
        justify-self: center;
        margin: 2rem auto 0;
    }

    .textareaWrapper {
        margin-top: 1rem;
    }

    .inputWrapper:not(.textareaWrapper) + .inputWrapper:not(.textareaWrapper) {
        margin-top: 1rem;

        @media (min-width: $breakpoint) {
            margin-top: 0;
        }
    }

    .inputWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;

        label {
            position: absolute;
            top: 0rem;
            left: 1.5rem;
            transform: translateY(100%);
            font-size: 0.75rem;
            opacity: 0;
            pointer-events: none;
            transition: transform 500ms;
        }

        &:not(.textareaWrapper) {
            @media (min-width: $breakpoint) {
                width: calc(50% - 0.5rem);
            }
        }
    }
}
