@import '../../styles/variables.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    width: 18rem;
    position: relative;

    .box {
        align-items: flex-start;
        width: 100%;
        padding: 1.25rem;
        position: absolute;
        top: 2.875rem;
        left: 0;
        box-shadow: 0 0 5px 0 var(--primary);
    }

    .text {
        position: relative;
        z-index: 1;
    }

    .list {
        margin: 0;
        list-style-type: none;
    }

    .item {
        a {
            display: flex;
            align-items: center;
            width: 100%;
            text-decoration: none;
        }

        &.disabled {
            opacity: 0.5;
        }

        &.unclickable {
            pointer-events: none;
        }

        &.bold {
            font-weight: 700;
            opacity: 1;
        }
    }

    .item + .item {
        margin-top: 0.625rem;
    }

    .checkButton {
        margin-right: 1rem;
    }

    .toggleButton {
        position: absolute;
        top: 50%;
        right: 0.5rem;
        transform: translateY(-50%);
        border: 1px solid var(--white);
    }
}

.progressbar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 2.625rem;
    position: relative;
    overflow: hidden;
    border-radius: 999px;
    color: var(--purple-dark);
    font-weight: 700;
    cursor: pointer;

    .progressBarBorder {
        border: 2px solid var(--orange);
        position: relative;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 999px;
    }

    .value {
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 500ms;
        background-color: var(--orange);
    }
}

.progressPopup {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $tablet) {
        padding: 2.5rem 5rem;
    }
}
