@import '../../styles/variables.scss';

.prefix {
    width: 95px;
    margin-right: .25rem;
    min-width: 95px;
}

.phone {
    margin: 0;
}
