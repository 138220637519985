.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 2.25rem;
    background: var(--primary);
}

.icon {
    width: 14px;
    color: var(--white);
}
