.wrapper {
    counter-reset: list;
    line-height: 1.75;
}

.item {
    display: flex;
    flex-direction: row;
    counter-increment: list;
    list-style: none;
    text-align: left;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    &:before {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        border-radius: 2rem;
        transform: translateY(-0.15rem);
        font-weight: 700;
        color: var(--white);
        background-color: var(--primary);
        content: counter(list) '.';
    }
}
