@keyframes load {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.wrapper {
    width: 100%;
    max-width: 47.5rem;
    position: relative;
    padding: 2rem;

    .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% - 6rem);
        height: 22rem;
        margin: 0 auto;
        text-align: center;
    }

    .arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border-radius: 2rem;
        position: absolute;
        top: 50%;
        z-index: 1;
        color: var(--white);
        background-color: var(--primary);

        &:hover {
            background-color: var(--purple-dark);
        }
    }

    .left {
        left: 0;
        transform: translateY(-50%) scaleX(-1);
    }

    .right {
        right: 0;
        transform: translateY(-50%);
    }

    :global(.react-multi-carousel-dot) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0.75rem, 1fr));
        gap: 0.75rem;
    }

    :global(.react-multi-carousel-dot-list) {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0.75rem, 1fr));
        gap: 0.75rem;
    }

    :global(.react-multi-carousel-dot-list button) {
        width: 100%;
        height: 0.75rem;
        border-radius: 0.75rem;
        position: relative;
        overflow: hidden;
        background: var(--purple-light);

        &:after {
            width: 0;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 0.75rem;
            background-color: var(--primary);
            animation: none;
            content: '';
        }

        &:global(.active):after {
            animation: load 15s; // This should correspond with config.CAROUSEL_AUTOPLAY_TIME
        }
    }

    .carousel {
        width: 100%;
    }
}
