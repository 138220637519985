@import '../../../styles/variables.scss';

.wrapper {
    .box:not(.mortgageBox) {
        align-items: flex-start;
        padding-left: 0;
        padding-right: 0;
    }

    .mortgageGrid {
        gap: 5rem;
    }

    .sportBox:not(.mortgageBox) {
        padding: 3rem;
    }

    .levelPrice {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .levelPriceLabel {
        margin-bottom: 0.5rem;
    }

    .levelItemTitle {
        display: flex;
    }

    .tooltipLevels {
        margin-right: 0.5rem;
        transform: translateY(-0.3rem);
    }

    .levelPriceValue {
        margin-bottom: 1rem;
        font-weight: 700;
        font-size: 1.5rem;
        color: var(--primary);
    }

    .productItemTitle {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .productsLeftTitle {
        display: flex;
        align-items: center;
        height: 2.625rem;
        text-align: left;
        position: relative;
    }

    .productsLeftTitle .pointer {
        cursor: pointer;
    }

    .subtitle {
        line-height: 1.625;
    }

    .mainTitle {
        display: flex;
        position: relative;
        font-size: 1.4rem;

        .title {
            display: inline-block;
            white-space: nowrap;
        }
    }

    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .finalPrice {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @media (min-width: $tablet) {
            align-items: center;
        }
    }

    .finalPriceSport {
        margin: 1.5rem 0;

        @media (min-width: $tablet) {
            margin: 0;
        }
    }

    .sportsBox {
        display: flex;
        flex-direction: column;

        @media (min-width: $tablet) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .sportsBoxWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .tableHeader {
        justify-content: center;
        font-size: 1.125rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;

        @media (min-width: $tablet) {
            display: grid;
            grid-template-columns: 1fr 1.8fr;
        }
    }

    .tableFooter {
        padding: 1.75rem 1rem 0;
    }

    .productList {
        padding: 1rem;
        background-color: var(--purple-light);
    }

    .fixedPrice {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 1.5rem;
    }

    .level {
        display: flex;
        justify-content: center;
    }

    .tooltip {
        margin-left: 0.75rem;
    }

    .help {
        margin-left: 0;
    }

    .levelContent {
        display: flex;
        flex-direction: column;        
    }

    .error {
        margin-top: 0.5rem;
    }

    .adjustButton {
        display: flex;
        justify-content: center;
        margin-top: 0.5rem;
        font-weight: 700;
        font-size: 1.125rem;
        color: var(--primary);
        cursor: pointer;

        &.isLoading {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .adjustIcon {
        width: 1.4rem;
        height: 1.4rem;
        margin-right: 0.15rem;
        color: var(--purple-dark);
    }

    .product {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: $tablet) {
        .level {
            background-color: var(--purple-light);
        }
    }

    @media (min-width: $tablet) {
        .tooltipLevels {
            position: absolute;
            right: calc(100% + 1rem);
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            margin-right: 0;
        }

        .level {
            width: 12rem;
            margin: 0 auto;
        }

        .levelTitle {
            display: flex;
            justify-content: center;
        }

        .toggleButton {
            margin-right: 1rem;
            transform: translateY(-0.3rem);
        }

        .levelLabels {
            margin-top: 2rem;

            li {
                position: relative;
            }

            li + li {
                margin-top: 1rem;
            }
        }

        .levelDetails {
            padding-right: 1.5rem;

            li + li {
                margin-top: 1rem;
            }

            &:first-of-type {
                margin-top: 2rem;
            }
        }

        .productList + .productList {
            margin-top: 3px;
        }
    }

    @media (min-width: $desktop) {
        .tableHeader {
            padding-left: 4.5rem;
        }

        .productList {
            padding-left: 4.5rem;
        }

        .tableFooter {
            padding-left: 4.5rem;
        }

        .toggleButton {
            display: flex;
            position: absolute;
            right: 100%;
            transform: none;
        }

        .levelField {
            margin-left: auto;
        }

        .levelLabels,
        .levelDetails {
            display: none;
        }

        .levelLabelsActive,
        .levelDetailsActive {
            display: block;
        }
    }

    @media (max-width: $tablet) {
        .levelHeader {
            display: flex;
            flex-direction: column;
        }

        .levelTitle {
            display: flex;
            justify-content: center;
            cursor: pointer;
        }

        .toggleButton {
            margin-right: 1rem;
            transform: translateY(-0.3rem);
        }

        .levelDetails {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5rem;
            color: #3b3b3b;
        }

        .levelPrice {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .levelPriceLabel {
            margin-bottom: 0.5rem;
        }

        .levelPriceValue {
            margin-bottom: 1rem;
            font-weight: 700;
            font-size: 1.5rem;
            color: var(--primary);
        }

        .productList {
            width: 100%;
        }
    }

    .buttonsWrapper {
        width: auto;
    }
}

.popup {
    .mortgageGrid {
        row-gap: 3.5rem;
    }
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.helpPopup {
    text-align: left;

    a {
        color: var(--primary);
        white-space: nowrap;
    }

    .list {
        font-size: 1.25rem;
    }

    @media (min-width: $tablet) {
        padding: 2.5rem 5rem;
    }
}

.recalculateButton {
    margin-top: 1rem;
}

.buttonsWrapper {
    margin: 0;
}


.hide {
 display:block !important;
 width: 0;
 height: 0;
 margin: 0;
 padding: 0;
    
}