.wrapper {
    width: 100%;
    max-width: 15rem;
    text-align: left;
    font-weight: 600;

    :global {
        .react-select__control {
            // padding: 0.4rem;
            background-color: var(--gray-light);
            color: var(--gray-dark);
        }
    }

    &.hasError {
        :global {
            .react-select__single-value {
                color: var(--gray-dark);
            }

            .react-select__control {
                border-color: var(--orange-dark);
                background: var(--orange-light);
            }

            .react-select__indicator {
                background-color: var(--orange-dark);
            }
        }
    }
}

:global {
    .react-select__control {
        padding: 0.27rem;
        height: 3.0147058824rem;
        font-size: 1.125rem;
    }

    .react-select__menu-portal {
        z-index: 901 !important;

        & > div {
            border: 2px solid var(--primary);
        }
    }

    .react-select__option {
        padding: 6px 20px !important;
    }
}
