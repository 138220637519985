@import '../../../../Eden.Paris.SimpleaOnline.Core//ClientApp//src/styles//core_global.scss';
@import '../styles/utils.scss';

:root {
    --purple-lightest: #f1e3fb;
    --purple-light: #ebd7f9;
    --purple-medium: #d8c6e5;
    --purple-medium2: #ccbde5;
    --purple-opacity: hsla(276, 75%, 61%,0.5);
    --purple: #a94fe6;
    --purple-dark: #433063;
    --gray-light: #f7f6f9; //#f5f4f7
    --gray-medium: #c0c0c0;
    --gray-dim: #777777;
    --gray-dark: #484747;
    --white: #fff;
    --orange: #f48965;
    --orange-light: #f7b7b7;
    --orange-dark: #e76060;
    --red: #e74c3c;
    
    --primary: var(--purple);
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    // font-size: 100%;
}

body {
    color: var(--purple-dark);
    font-family: 'Open Sans', sans-serif;
    line-height: 1.4;
    overflow: hidden;
    overflow-y: auto;
}

textarea:focus,
input:focus {
    outline: none;
}

a {
    color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    margin-top: 0;
    margin-bottom: 0;
    // margin-bottom: 1rem;
}

ul,
ol {
    padding-left: 0;
}

ul {
    margin: 0;
    list-style-type: none;
}

button {
    padding: 0;
    border: 0;
    cursor: pointer;
    background: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.text--center {
    text-align: center;
}

.text--nowrap {
    display: inline-block;
    white-space: nowrap;
}

.text--bold {
    font-size: 700;
}

.center {
    align-self: center;
    text-align: center;
}

.radio-binary {
    width: 10rem;
    justify-content: center;
}

.field-medium div:first-of-type {
    max-width: 100%;
    width: 14rem;
}

.field-small div:first-of-type {
    max-width: 100%;
    width: 10rem;
}

.field-tiny div:first-of-type {
    max-width: 100%;
    width: 6rem;
}

.text-left {
    text-align: left !important;
}

.flex {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-a-center {
    align-items: center;
}

.justify-center {
    justify-content: center !important;
}

.justify-start {
    justify-content: flex-start !important;
}

.justify-end {
    justify-content: flex-end !important;
}

body {
    .react-select__control {
        min-height: 2.375rem;
        min-width: 0;
        color: var(--primary);
        background-color: transparent;
        padding-right: 0.25rem;
        border-radius: 999px;
        border: 2px solid var(--primary);
        overflow: hidden;
        cursor: pointer;

        &:hover {
            border-color: var(--primary);
        }
    }
    .react-select__menu {
        padding: 1rem 0;
        border-radius: 1.25rem;
        overflow: hidden;
        background-color: var(--gray-light);
    }
    .react-select__control--is-focused {
        // box-shadow: 0 0 0 1px var(--primary);
        box-shadow: none;
    }
    .react-select__control--is-hovered {
        box-shadow: none;
    }
    .react-select__single-value {
        color: var(--purple-dark);
    }
    .react-select__indicator {
        padding: 0.3125rem;
        border-radius: 999px;
        color: var(--white);
        background-color: var(--primary);

        svg {
            width: 1.25rem;
            height: 1.25rem;
        }
    }
    .react-select__option {
        padding: 0.25rem 1rem;
        color: var(--primary);
        background-color: transparent;
        cursor: pointer;

        &--is-focused,
        &--is-hovered {
            color: var(--white);
            background-color: var(--primary);
        }
    }
    .react-select__indicator-separator {
        display: none;
    }
    .react-select__value-container {
        padding: 0 0 0 1rem;
    }

    .react-select__placeholder {
        color: var(--purple-dark);
    }

    .step {
        .question-box {
                .question-subitem {
                .question-item:last-child{
                    margin-bottom: 0;

                    .question-item-divider:last-child {
                        display: none;
                    }
                }
                
            }
        }

        .question-item {
            margin-bottom: 3.5rem;

            .question-subitem:last-child {
                margin-bottom: 0;

                .question-item-divider {
                    display: none;
                }
            }
        }

        .question-item-divider {
            height: 1px;
            background-color: #e1baff;
        }

        .question-subitem {
            width: 100%;
            margin-bottom: 3.5rem;

            &:nth-child(even) {
                width: calc(100% + 2rem);
                padding: 3.5rem 1rem;
                background-color: var(--purple-light);

                @media (min-width: $tablet) {
                    width: calc(100% + 6rem);
                    margin-left: -3rem;
                    margin-right: -3rem;
                    padding: 3.5rem 3rem;
                }
            }
        }
    }

    .help {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        flex-grow: 0;
        width: 2rem !important;
        height: 2rem !important;
        margin-left: 1rem;
        border: 2px solid var(--primary);
        color: var(--primary);
        font-size: 1.125rem;
        font-weight: 700;
        border-radius: 100%;
        cursor: pointer;

        &:hover {
            color: var(--white);
            background-color: var(--primary);
        }
    }
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

hr {
    width: 100%;
    height: 2px;
    margin: 1rem 0;
    border: 0;
    background: red;
}

#daktela-web .dw-button {
    transform: translateY(0);
    transition: 500ms transform;
}

.scrolled-down #daktela-web .dw-button {
    transform: translateY(-60px);
}

.table-header-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    // column-gap: 1rem;
    // row-gap: 0.25rem;
    margin: 0 auto;
}

.box-title-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: -0.25rem -0.5rem;

    & > * {
        margin: 0.25rem 0.5rem;
    }

    @media (min-width: $tablet) {
        justify-content: flex-start;
    }
}

.react-date-picker__wrapper {
    // 41px height (odd number, for better centering), 16px base height, 85% page view zoom
    // 41 / (16 * 0.85) = 3.0147058824 nice number, it was not my idea to set 85%
    height: 3.0147058824rem !important;
    font-size: 1.125rem !important;
    padding: 0 1rem;
    border: 2px solid var(--primary) !important;
    border-radius: 999px;
    background-color: var(--white);
}

.react-date-picker__inputGroup__leadingZero,
.react-date-picker__inputGroup__input {
    display: inline-block !important;
    color: var(--gray-dark) !important;
    font-weight: 600 !important;
}

.react-date-picker__inputGroup__input::placeholder {
    font-weight: 400 !important;
}

.react-date-picker__inputGroup__input:invalid {
    background-color: transparent !important;
}

.react-date-picker__button {
    display: none !important;
}

.react-date-picker__inputGroup__divider {
    width: 12px;
    display: inline-block;
    text-indent: -9999px;
    position: relative;

    &:after {
        display: block;
        width: 2px;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        background: var(--primary);
        transform: rotate(15deg) translateX(-4px) translateY(-50%);
        content: '';
    }
}
