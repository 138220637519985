.wrapper {
}

.checkbox {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;

    &:checked + .label {
        color: var(--white) !important;
        background-color: var(--primary);
    }
}

.hasError {
    background-color: var(--orange-light) !important;
    border-color: var(--orange-dark) !important;
    color: var(--orange-dark) !important;
}
