@import '../../styles/variables.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1rem;

    @media (min-width: $tablet) {
        flex-direction: row;
        justify-content: space-between;
        padding: 1.5rem 2.5rem;
    }

    .links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media (min-width: $tablet) {
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: 2.5rem;
        }
    }

    .logo {
        margin-bottom: 3.5rem;

        @media (min-width: $tablet) {
            margin-bottom: 0;
        }
    }

    .copyright {
        margin-bottom: 3.5rem;

        @media (min-width: $tablet) {
            margin-bottom: 0;
        }
    }

    .linkItem + .linkItem {
        margin-top: 1.5rem;

        @media (min-width: $tablet) {
            margin-top: 0;
        }
    }
}
