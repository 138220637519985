@import '../../styles/variables.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 32rem;
    text-align: left;

    .file {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

        @media (min-width: $tablet) {
            flex-direction: row;
        }
    }

    .file + .file {
        margin-top: 1rem;
    }

    .download {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        font-weight: 700;
        color: var(--primary);
        text-decoration: none;

        @media (min-width: $tablet) {
            width: auto;
        }
    }

    .fileName {
        margin-right: 1rem;
    }

    .documentIcon {
        flex:none;
        width: 1.45rem; // 20px
        height: 2.4rem; // 28px
        margin-right: 1rem;
        color: var(--purple-dark);
    }

    .downloadIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.375rem;
        margin-right: 0.5rem;
    }
}
