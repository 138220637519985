.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--primary);
    cursor: pointer;

    &:hover {
        .icon {
            color: var(--white);
            background-color: var(--primary);
        }

        .text {
            text-decoration: none;
        }
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        border: 2px solid var(--primary);
        border-radius: 2rem;
        position: relative;
        font-size: 1.5rem;
        font-weight: 500;
        pointer-events: none;

        &:before,
        &:after {
            width: 12px;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: currentColor;
            content: '';
        }

        &.increase {
            &:after {
                transform: translate(-50%, -50%) rotate(90deg);
            }
        }

        &.decrease {
            &:after {
                content: none;
            }
        }
    }

    .text {
        display: inline-block;
        margin-left: 0.625rem;
        text-decoration: underline;
    }
}
