.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    color: var(--white);
    background-color: var(--primary);
    cursor: pointer;

    &.secondary {
        background-color: var(--orange);
    }
}

.opened .icon {
    transform: rotate(90deg) scaleX(-1);
}

.icon {
    display: inline-flex;
    width: 0.875rem;
    transform: rotate(90deg);
    transition: transform 500ms;
}
