@import '../../styles/variables.scss';

.wrapper {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;

    @media (min-width: $tablet) {
        font-size: 3.5rem;
    }
}

.title {
    margin-bottom: 1.5rem;
}

.price {
    display: inline-flex;
    font-weight: 700;
    white-space: nowrap;
}

.priceBefore {
    position: relative;
    color: var(--purple-light);

    @media (min-width: $tablet) {
        margin-bottom: -0.375rem;
    }

    &:after {
        width: calc(100% + 3rem);
        height: 2px;
        position: absolute;
        top: 50%;
        left: -1.5rem;
        background-color: var(--purple-dark);
        content: '';
    }
}

.priceAfter {
    color: var(--primary);
}

.infoText {
    font-weight: 700;
}
