@import '../../styles/variables.scss';

.wrapper {
    display: inline-flex;

    width: 7.1875rem;

    @media (min-width: $tablet) {
        width: 8.5rem;
    }
}

.link {
    display: block;
    width: 100%;

    img {
        width: 100%;
        height: auto;
    }
}

.button {
    margin: 0 1rem;
}

.buttonWrapper {
    width: 50%;
    @media (max-width: $tablet) {
        width: 100%;
        display: block;
    }
}

.buttonGroupWrapper {
    @media (max-width: $tablet) {
        flex-direction: column;
    }
}
