.wrapper {
    .addMore {
        align-self: center;
        margin: auto;
    }

    .select {
        max-width: 25rem;
    }
}
