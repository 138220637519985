@import '../../styles/variables.scss';

.wrapper {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    text-align: center;

    @media (min-width: $tablet) {
        padding: 0 2rem;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > form {
            width: 100%;
        }
    }

    .text {
        line-height: 1.8;
        font-size: 1.25rem;
        position: relative;
        z-index: 1;

        br {
            display: none;

            @media (min-width: $tablet) {
                display: block;
            }
        }
    }

    .header {
        width: 100%;
        max-width: 60rem;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }

    .errorBox {
        width: 100%;
        padding: 2rem;
        border-radius: 2rem;
        background-color: var(--orange-light);
        text-align: left;

        @media (min-width: $tablet) {
            padding: 3rem;
        }
    }

    .errorList {
        text-align: left;
    }
}
