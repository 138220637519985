@import '../../styles/variables.scss';

.wrapper {
    display: inline-flex;
    align-items: center;
    padding-left: 4rem !important;
    position: relative;
}

.basket {
    margin-right: 1rem;
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);

    svg {
        width: 2rem;
    }

    @media (min-width: $tablet) {
        left: 1rem;
    }
}

.count {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 1.125rem;
    position: absolute;
    bottom: -0.1875rem;
    right: -0.4375rem;
    color: var(--white);
    font-weight: 700;
    font-size: 0.875rem;
    background-color: var(--purple);
}
