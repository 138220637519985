.wrapper {
    display: inline-block;
    margin-top: 0.25rem;
    color: var(--orange-dark);
    font-size: 0.8rem;

    &.left {
        align-self: flex-start;
    }

    &.right {
        align-self: flex-end;
    }
}
