@import '../../styles/variables.scss';

.wrapper {
    padding: 4rem 2rem;
    text-align: center;

    .inner {
        width: 100%;
        max-width: 73.125rem;
        margin: 0 auto;
    }

    .title {
        margin-bottom: 0.25rem;
    }

    .text {
        margin-bottom: 2.5rem;
    }

    .list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(14rem, 17rem));
        justify-content: center;
        gap: 3rem;
        font-size: 0.875rem;

        @media (min-width: $tablet) {
        }
    }

    .image {
        width: auto;
        height: 10rem;
        margin-bottom: 1.5rem;
    }

    .itemTitle {
        margin-bottom: 1rem;
    }
}
