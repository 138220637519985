
@import '../../../styles/variables.scss';    

.wrapper {
    .smileys {
        display: flex;
    }

    .smileyItem {
        input {
            width: 0;
            height: 0;
            overflow: hidden;
            visibility: hidden;

            &:checked + .label {
                color: var(--white);
                background-color: var(--primary);
            }
        }
    }
    
    .textarea {
        margin-bottom: 1.5rem;
        margin-left: auto;
        margin-right: auto;
        resize: none;
    }
}

.feedbackText {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;
    @media (min-width: $tablet) {
        gap: 70px;
    }
}
