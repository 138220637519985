.wrapper {
    svg {
        width: 100%;
        height: auto;
    }
}

.small {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
}

.medium {
    width: 2rem;
}

.large {
    width: 3rem;
}
