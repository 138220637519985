@import '../../../styles/variables.scss';

.wrapper {
    :global(.item) {
        padding: 3.5rem 0;
    }

    :global(.item + .item) {
        padding-top: 0;
    }
}
