.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .animation {
        width: 23.375rem;
        height: 23.25rem;
        margin: -3.125rem auto 0;
        overflow: hidden;

        @media (min-width: 50em) {
            width: 100%;
            max-width: 73.125rem;
            height: 38.75rem;
            aspect-ratio: 1.8870967742/1;
            margin-top: -14.375rem;
        }
    }

    .animationVideo {
        display: inline-block;
        height: calc(100% + 0.125rem);
        transform: translateY(-0.0625rem);

        @media (min-width: 50em) {
            width: calc(100% + 0.375rem);
            transform: translateX(-0.1875rem);
            margin: 0 auto;
        }
    }

    .countdown {
        position: relative;
        z-index: 1;
    }

    .carousel {
        margin: 0 auto;
    }

    .list {
        width: 100%;
        max-width: 40rem;
        margin: 0 auto;
        text-align: left;
    }
}
