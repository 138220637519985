@import '../../styles/variables.scss';

.wrapper {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    line-height: 1.8;
    white-space: pre-wrap;

    &:not(.small) {
        @media (min-width: $tablet) {
            font-size: 1.25rem;
        }
    }
}
