@import '../../styles/variables.scss';

.wrapper {
    position: relative;
    font-size: 1rem;

    &:hover {
        .tooltipBox {
            display: block;
            max-width: calc(100vw - 5rem);
        }

        .tooltipTrigger {
            color: var(--white);
            background-color: var(--purple);
        }
    }

    .tooltipTrigger {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 1.5rem;
        color: var(--purple);
        font-weight: 700;
        font-size: 1rem;
        background-color: var(--white);
        cursor: pointer;
    }

    .tooltipBox {
        display: none;
        width: 20rem;
        position: absolute;
        top: 100%;
        z-index: 100;
        transform: translateY(0.5rem);
        padding: 1rem;
        background-color: var(--purple-medium);
        border-radius: 0.5rem;
    }

    .tooltipBox-left {
        left:0px;
        @media (max-width: $tablet) { 
            left: calc(0px - 6vh);
        }
    }

    .tooltipBox-right {
        right:0px;
        @media (max-width: $tablet) { 
            right: calc(0px - 6vh);
        }
    }

    .tooltipBox-center {
        left: 50%;
        transform: translateY(0.5rem) translateX(-50%);
    }
}
