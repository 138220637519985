@import '../../../styles/variables.scss';

.wrapper {
    .help {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        margin-left: 1rem;
        border: 2px solid var(--primary);
        color: var(--primary);
        border-radius: 100%;
        cursor: pointer;

        &:hover {
            color: var(--white);
            background-color: var(--primary);
        }
    }

    .noBankIdLink {
        cursor: pointer;
    }
}

.popup {
    width: 100%;

    @media (min-width: $tablet) {
        padding: 0 50px;
    }
}

.loading {
    opacity: 0.65;
    pointer-events: none;
}

.countdownAlert {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    text-align: left;
    line-height: 1.5;

    svg {
        fill: var(--primary);
    }
}