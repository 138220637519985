@import '../../styles/variables.scss';

.wrapper {
    width: 100%;
    position: relative;
    color: var(--gray-dark);
    font-weight: 600;
    text-align: left;

    .editIcon {
        position: absolute;
        display: none; // display only on hover of coverageContent
        margin-left: 8px;
        margin-top: 3px;
        //width: 2rem !important;
    }

    .header {
        margin: -2rem -1rem 0;
        padding: 2rem 1rem;
        background-color: var(--purple-light);
        border-radius: 2rem 2rem 0 0;

        @media (min-width: $tablet) {
            margin: -4.5rem -3rem 0;
            padding: 3rem;
        }
    }

    .label {
        width: 100%;
    }

    .input {
        width: 100%;
        max-width: 20rem;
        padding: 0.5rem 1.5rem 0.6rem 3rem;
        border: 2px solid var(--primary);
        border-radius: 999px;
        background-color: var(--gray-light);

        &::placeholder {
            font-weight: 600;
        }
    }

    .sortButton {
        margin-bottom: 1rem;
        margin-right: 4.4rem;
        text-decoration: underline;
        font-weight: 700;
        background-color: transparent;
        color: var(--purple-medium2);

        &.active {
            color: var(--purple-dark);
        }
    }

    .filters {
        display: flex;
        margin: 2rem 0;
    }

    .jobList {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: -1rem;
        margin-right: -1rem;
        text-align: center;
        font-weight: 400;

        @media (min-width: $tablet) {
            margin-left: -3rem;
            margin-right: -3rem;
        }

        @media (min-width: $desktop) {
            flex-wrap: nowrap;
        }

        .checkButton {
            margin-right: 1rem;
        }

        .select {
            width: 12rem;
            margin-right: 0;
            flex-shrink: 0;
            flex-basis: 100%;
            margin-left: 0;

            @media (min-width: $desktop) {
                margin-left: auto;
                flex-basis: auto;
            }
        }

        li.active {
            background-color: var(--purple-light);
        }

        li {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            // row-gap: 0.5rem;
            width: 100%;
            min-height: 2rem;
            padding: 0.3125rem 1rem;
            cursor: pointer;
            text-align: left;

            @media (min-width: $tablet) {
                padding-right: 3rem;
                padding-left: 3rem;
            }
        }

        // li + li {
        //     margin-top: 0.625rem;
        // }
    }

    .jobName {
        // max-width: calc(100% - 22rem);
        width: 100%;
        margin-right: auto;
        max-width: calc(100% - 7rem);

        @media (min-width: $desktop) {
            width: auto;
            margin-right: 0;
        }
    }

    .showMore {
        margin-top: 2rem;
        text-decoration: underline;
        font-weight: 700;
        color: var(--purple-dark);
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    .tooltip {
        margin-left: 1rem;
    }

    .tooltipBox {
        right: 0 !important;
        left: auto !important;

        @media (min-width: $tablet) {
            right: auto !important;
            left: 0 !important;
        }
    }

    .searchWrapper {
        display: flex;
    }

    .searchWrapper:hover .editIcon {
        @media (min-width: $tablet) {
            display: block;
        }
    }

    .searchWrapper:focus .editIcon {
        @media (min-width: $tablet) {
            display: block;
        }
    }

    .filters {
        display: flex;
        justify-content: space-between;
    }

    .filterLevelLabel {
        display: none;
        width: 12rem;
        align-self: flex-end;
        text-align: center;
        font-weight: 700;

        @media (min-width: 50em) {
            display: flex;
            align-items: center;
        }
    }

    .tooltipLevel {
        margin-left: 1rem;
    }

    .inlineError {
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: 0;

        @media (min-width: $desktop) {
            width: auto;
        }
    }

    .inlineError + .select {
        @media (min-width: $desktop) {
            margin-left: 1rem;
        }
    }
}
