@import '../../styles/variables.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    position: relative;
    background-color: var(--gray-light);

    .content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-grow: 1;
        padding: 2.5rem 1rem;
        // overflow: hidden;
        position: relative;

        @media (min-width: $tablet) {
            padding: 3.75rem 1rem;
        }
    }

    .data {
        position: absolute;
        left: 1rem;
        top: 8rem;
        z-index: 100;
        font-size: 0.75rem;
    }

    &.noPadding {
        .content {
            @media (min-width: 0) {
                padding: 0;
            }
        }
    }
}
