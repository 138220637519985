@import '../../styles/variables.scss';

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 3.125rem;
    border: 2px solid var(--purple);
    color: var(--purple);
    background-color: transparent;
    cursor: pointer;
    transition: 500ms all;

    @media (min-width: $tablet) {
        width: 4rem;
        height: 4rem;
        border-radius: 4rem;
    }

    &:hover {
        color: var(--white);
        background-color: var(--purple);
    }

    .cross {
        width: 1rem;
        height: 1rem;
        position: relative;
        transform: rotate(45deg);

        @media (min-width: $tablet) {
            width: 1.5rem;
            height: 1.5rem;
        }

        &:before,
        &:after {
            display: block;
            width: 100%;
            height: 2px;
            position: absolute;
            background-color: currentColor;
            content: '';
        }

        &:before {
            top: 50%;
            transform: translateY(-50%);
        }

        &:after {
            top: 50%;
            transform: translateY(-50%) rotate(90deg);
        }
    }
}
