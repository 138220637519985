@import '../../styles/variables';

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // column-gap: 1rem;
    // row-gap: 0.5rem;
    flex-wrap: wrap;
    width: 100%;
    padding: 1rem 1.25rem;
    background-color: var(--white);
    position: relative;

    @media (min-width: $desktop-wide) {
        height: 6.25rem;
        padding: 0 2.5rem;
    }

    .progress {
        width: 100%;
        // margin-top: 2rem;
        order: 6;
        position: relative;
        z-index: 50;

        @media (min-width: 30em) {
            width: 12rem;
        }

        @media (min-width: $tablet) {
            margin-left: 1rem;
            width: 18rem;
        }

        @media (min-width: $desktop) {
            order: 3;
            margin-top: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-left: 0;
        }
    }

    .logo {
        order: 1;

        @media (min-width: $tablet) {
            display: block;
            order: 2;
            margin-right: 1rem;
        }
    }

    .back {
        display: none;
        // margin-right: 1rem;
        // margin-top: 2rem;
        order: 5;

        @media (min-width: $tablet) {
            display: block;
            order: 2;
            margin-left: 1rem;
        }
    }

    .autoMarginRight {
        @media (min-width: $tablet) {
            margin-right: auto;
        }
    }

    .save {
        display: block;
        order: 2;

        @media (min-width: $tablet) {
            display: block;
            order: 3;
            margin-left: 1rem;
            margin-right: auto;
        }
    }

    .checkout {
        // margin-right: 1rem;
        // margin-left: auto;
        order: 2;

        @media (min-width: $tablet) {
            order: 3;
            margin-left: auto;
            margin-right: 1rem;
        }

        @media (min-width: 50em) {
            order: 4;
        }
    }

    .callButtonWide {
        display: none;

        @media (min-width: $desktop-wide) {
            display: block;
            order: 5;
        }
    }

    .callButtonNarrow {
        order: 3;

        @media (min-width: 50em) {
            order: 5;
        }

        @media (min-width: $desktop-wide) {
            display: none;
        }
    }

    .lineWrap {
        display: none;
        width: 100%;
        order: 4;

        @media (min-width: $desktop-wide) {
            display: none;
        }
    }
}

.form {
    width: 100%;
}

.checkoutWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: $tablet) {
        width: calc(100% + 2.625rem + 2.625rem);
    }
}

.checkoutContent {
    table-layout: fixed;
    text-align: left;
    border-spacing: 0 50px;
    border-collapse: collapse;
    background-color: var(--purple-light);

    th,
    td {
        padding: 0.5rem;

        @media (min-width: $tablet) {
            padding: 0.75rem 2.625rem;
        }
    }

    th {
        font-weight: 400;
        text-align: right;
        background-color: var(--purple-lightest);
    }

    td {
        font-size: 1.125rem;
        font-weight: 700;
        border-bottom: 3px solid var(--purple-lightest);

        &:nth-child(3n) {
            text-align: right;
        }
    }

    .checkoutInsuredValue {
        font-size: 1.5rem;
        text-align: right;
        white-space: nowrap;
    }

    .checkoutSubitem {
        font-size: 1rem;

        @media (min-width: $tablet) {
            padding-left: 5.25rem;
        }
    }

    .priceForMonthCell {
        display: none;

        @media (min-width: $mobile-narrow) {
            display: table-cell;
        }
    }
}

.checkoutButton {
    align-self: center;
}
