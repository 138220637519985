@import '../../styles/variables.scss';

.wrapper {
    display: flex;
    position: relative;
    max-width: 20rem;
    margin: 0 auto;
    color: var(--gray-dark);
    font-weight: 600;
}

.formikWrapper {
    display: flex;
    position: relative;
    max-width: 20rem;
    margin: 0 auto;
    color: var(--gray-dark);
    font-weight: 600;
}

.loading {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%
}

.loader { 
    position: absolute; 
    top: 8px; 
    z-index: 2;
}

.input {
    width: 100%;
    height: 3.0147058824rem;
    padding: 0 0 0 2.5rem;
    border: 2px solid var(--primary);
    border-radius: 999px;
    font-weight: 600;
    font-size: 1.125rem;
    color: var(--gray-dark);
    background-color: var(--gray-light);
    line-height: 0;

    &::placeholder {
        font-weight: 600;
    }
    &:disabled {
        width: 100%;
        padding: 0.6rem 1.5rem;
        max-width: 20rem;
        text-align: center;
        min-height: 2.75rem;
        margin: 0 auto;
        border: 2px solid var(--gray-medium);
        border-radius: 999px;
        font-weight: 600;
        font-size: 1.125rem;
        color: var(--gray-dim);
        background-color: var(--gray-light);
    }
}

.editIcon {
    position: absolute;
    display: none; // display only on hover of coverageContent
    margin-left: 8px;
    left: 0;
    margin-top: 5px;
    width: 2rem !important;

}

.wrapper:hover .editIcon {
    @media (min-width: $tablet) {
        display: block;
    }
}

.inputWithSuffix {
    padding-right: 3.5rem;
    text-align: right;
}

.suffix {
    display: flex;
    align-items: center;
    height: 3.0147058824rem;
    position: absolute;
    top: 0;
    left: calc(100% - 3.5rem);
    padding: 0.59rem 0.25rem;
    font-size: 1.125rem;
    line-height: 0;
}

.hasError {
    background-color: var(--orange-light);
    border-color: var(--orange-dark);
}
