.wrapper {
    .form {
        width: 100%;
    }

    .searchBox {
        overflow: hidden;
    }

    .agreeCheckbox {
        justify-content: center;
    }

    .birthDtError :global(.react-date-picker__wrapper) {
        background-color: var(--orange-light) !important;
        border-color: var(--orange-dark) !important;
        color: var(--orange-dark) !important;
    }
}

.popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .popupInfo {
        margin-bottom: 0;
        color: var(--gray-dark);
    }

    .openContactFormLink {
        text-decoration: underline;
        cursor: pointer;
    }
}
