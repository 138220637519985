@import '../../styles/variables.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2rem 1rem;
    border-radius: 2rem;
    background-color: var(--purple-lightest);
    opacity: 1;
    transition: 500ms opacity;
    // overflow: hidden;

    @media (min-width: $tablet) {
        padding: 4.5rem 3rem;
    }

    .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 55rem;
        margin: 0 auto;
    }
}

.dark {
    color: var(--white);
    background-color: var(--purple-dark);
}

.loading {
    opacity: 0.65;
    pointer-events: none;
}
