@import '../../../styles/variables.scss';

.wrapper {
    .list {
        width: 100%;
        max-width: 40rem;
        margin: 0 auto;
        text-align: left;
    }

    .paymentTable {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0.75rem;

        @media (min-width: $tablet) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    .paymentTableItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .paymentTableTitle {
        margin-bottom: 1.25rem;
        font-weight: 700;
        font-size: 1.125rem;
    }

    .paymentTableValue {
        display: inline-block;
        padding: 0.75rem 2.375rem;
        border: 2px solid var(--primary);
        border-radius: 999px;
        color: var(--gray-dark);
        font-weight: 700;
        font-size: 1.125rem;
        background-color: var(--white);
    }

    .errorTitle {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}
