@import '../../styles/variables.scss';

.wrapper {
    display: inline-block;
    font-weight: 700;

    &.xl {
        font-size: 2rem;

        @media (min-width: $tablet) {
            font-size: 2.5rem;
        }
    }
    &.lg {
        font-size: 2rem;

        @media (min-width: $tablet) {
            font-size: 2.25rem;
        }
    }
    &.md {
        font-size: 1.5rem;
    }
    &.sm {
        font-size: 1rem;
    }

    &.light {
        color: var(--purple);
    }

    &.white {
        color: var(--white);
    }
}
