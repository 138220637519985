@import '../../styles/variables';

$positiveWidthMobile: 120px;
$negativeWidthMobile: 90px;
$positiveWidth: 140px;
$negativeWidth: 90px;

.wrapper {
    display: flex;
    align-items: stretch;
    height: 3rem;
    border: 2px solid var(--purple-opacity);
    border-radius: 999px;
    color: var(--primary);
    font-weight: 700;
    font-size: 1.125rem;
    cursor: pointer;
    position: relative;

    @media (min-width: $tablet) {
        height: 4.5rem;
    }

    &:after {
        width: $positiveWidthMobile;
        height: calc(100% + 4px);
        position: absolute;
        left: -2px;
        top: -2px;
        z-index: 1;
        border: 3px solid var(--primary);
        border-radius: 500px;
        pointer-events: none;
        transform: translateX(0);
        transition: 500ms all;
        content: '';

        @media (min-width: $tablet) {
            width: $positiveWidth;
        }
    }

    &:not(.checked):after {
        width: $negativeWidthMobile;
        transform: translateX($positiveWidthMobile);

        @media (min-width: $tablet) {
            width: $negativeWidth;
            transform: translateX($positiveWidth);
        }
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .checkButton {
        margin-right: 0.5rem;
        border-color: var(--purple-opacity);
    }

    .toggler {
        display: flex;
        align-items: center;
        padding: 0.25rem 2rem;
        user-select: none;
    }

    .positiveToggler {
        width: $positiveWidthMobile;
        justify-content: flex-start;
        padding-left: 0.25rem;
        padding-right: 2rem;
        position: relative;
        color: var(--purple-opacity);

        @media (min-width: $tablet) {
            width: $positiveWidth;
        }
    }

    .positiveTogglerActive {
        font-weight: bolder;
        color: var(--primary);
    }

    .negativeToggler {
        width: calc($negativeWidthMobile - 4px);
        justify-content: center;
        text-decoration: none;
        font-weight: lighter;
        color: var(--purple-opacity);

        @media (min-width: $tablet) {
            width: calc($negativeWidth - 4px);
        }
    }

    .negativeTogglerActive {
        font-weight: bolder;
        color: var(--primary);
    }

    .price {
        white-space: nowrap;
    }
}
