.wrapper {
    width: 100%;
    max-width: 40rem;
    display: flex;
    position: relative;
    color: var(--gray-dark);
    font-weight: 600;
    resize: none;
}

.input {
    width: 100%;
    min-height: 10rem;
    padding: 1.5rem;
    border: 2px solid var(--primary);
    border-radius: 2rem;
    background-color: var(--gray-light);
    resize: none;

    &::placeholder {
        font-weight: 600;
    }
}
