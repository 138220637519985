.wrapper {
    .inner {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: auto;
        max-width: 20rem;
        height: 2.375rem;
        margin-left: auto;
        margin-right: auto;
        border: 2px solid var(--primary);
        border-radius: 2.375rem;
        color: var(--primary);
        font-size: 1rem;
        font-weight: 700;
        overflow: hidden;
    }

    .value {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -2px 0;
        padding: 0 1.25rem;
        border-radius: 2.375rem;
        cursor: pointer;
    }

    .valueActive {
        color: var(--white);
        background-color: var(--primary);
    }
}
