@import '../../../styles/variables';

.wrapper {
    .box {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        text-align: left;
    }

    .leftColumn {
        flex-shrink: 0;
    }

    .boxItem {
        @media (min-width: $tablet) {
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
            column-gap: 2rem;
            width: 100%;
        }
    }

    .boxItem + .boxItem {
        margin-top: 2rem;
    }

    .list {
        margin-bottom: 0;

        @media (min-width: $tablet) {
            width: 75%;
        }
    }
}
