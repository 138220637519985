@import '../../../styles/variables.scss';

.wrapper {
    .list {
        width: 100%;
        max-width: 40rem;
        margin: 0 auto;
        text-align: left;
    }

    .errorTitle {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}
