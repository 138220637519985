@import '../../../styles/variables';

.wrapper {
    .list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 28rem));
        justify-content: center;
        gap: 1rem;
        flex-grow: 1;
        width: 100%;

        @media (min-width: $tablet) {
            gap: 2rem;
        }
    }

    .box {
        height: 100%;

        @media (min-width: $tablet) {
            min-height: 30rem;
        }
    }

    .image {
        width: 5.625rem;

        @media (min-width: $tablet) {
            width: 11.25rem;
        }
    }

    .link {
        text-decoration: none;
    }

    .button {
        margin-top: auto;
    }
}
