.wrapper {
    width: auto;
    display: inline-flex;
    align-self: center;
    padding: 1.5rem 3rem;
    font-size: 3.75rem;
    font-weight: 700;
    line-height: 1;
    cursor: default;
}

.finished {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 2.5rem;
}
