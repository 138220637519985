@import '../../../styles/variables.scss';

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    padding: 4.5rem 2.25rem;
    color: var(--white);
    text-align: center;
    background-color: var(--purple-dark);

    @media screen and (min-width: $tablet) {
        flex-direction: row;
    }

    &.page-500 {
        padding: 0;

        .inner {
            padding: 2rem;
        }
    }

    &.page-404 {
        width: calc(100% + 2rem);
        height: calc(100% + 5rem);
        margin-left: -1rem;
        margin-top: -2.5rem;

        @media screen and (min-width: $tablet) {
            height: calc(100% + 7.5rem);
            margin-top: -3.75rem;
        }
    }

    .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 90rem;

        @media screen and (min-width: $tablet) {
            flex-direction: row;
        }
    }

    .content {
        max-width: 54rem;
        order: 2;

        @media screen and (min-width: $tablet) {
            order: 1;
            text-align: left;
            padding-right: 2rem;
        }

        @media screen and (min-width: $desktop) {
            width: 58%;
        }
    }

    .image {
        order: 1;
        width: 100%;
        height: auto;
        margin-bottom: 1.6rem;
        max-width: 18.75rem;

        @media screen and (min-width: $tablet) {
            order: 2;
        }

        @media screen and (min-width: $desktop) {
            width: 33.333%;
            max-width: 26rem;
        }
    }

    .title {
        font-size: 3.4rem;
        color: var(--purple);

        @media screen and (min-width: $tablet) {
            font-size: 6rem;
        }
    }

    .text {
        font-size: 1.8rem;
        font-weight: 500;

        @media screen and (min-width: $desktop-wide) {
            font-size: 2.6rem;
        }
    }
}
