@import '../../styles/variables';

.wrapper {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    justify-content: space-between;
    align-items: center;
    min-height: 10rem;

    @media (min-width: $tablet) {
        margin: 2rem 0;
    }

    .decoration {
        text-decoration: underline;
        cursor: pointer;
    }

    .decoration:hover {
        text-decoration: none;
    }
}
