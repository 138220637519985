.wrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.link {
    text-decoration: none;
    font-weight: 700;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.625rem;
    border-radius: 2.25rem;
    color: var(--white);
    background-color: var(--purple-dark);

    svg {
        width: 1.125rem;
    }
}
