@import '../../../styles/variables.scss';

.wrapper {
    .toggleTopButtons {
        width: auto;
    }

    .select {
        max-width: 25rem;
    }

    .promoLabel {
        margin-bottom: 0;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    .promoField {
        margin-top: 1.625rem;
    }
}
