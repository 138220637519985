@import '../../styles/variables.scss';

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.wrapper {
    width: 100%;
    max-width: 73.125rem;
    min-height: 20rem;
    max-height: calc(100% - 5rem);
    padding: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 901;

    ::-webkit-scrollbar {
        width: 14px;
        height: 18px;
    }

    ::-webkit-scrollbar-thumb {
        height: 6px;
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        background-color: #ccbde57b;
        -webkit-border-radius: 7px;
        -webkit-box-shadow: inset -1px -1px 0px #ccbde530, inset 1px 1px 0px #ccbde530;
    }

    ::-webkit-scrollbar-button {
        display: none;
        width: 0;
        height: 0;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex-grow: 1;
        width: 100%;
        margin: 5.625rem 0 2.625rem;
        padding: 0 1rem;
        overflow-y: auto;
        overflow-x: hidden;

        @media (min-width: $tablet) {
            margin: 2.625rem 0;
            padding: 0 2.625rem;
        }
    }

    .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        // max-width: 49.375rem;
        margin: 0 auto;
        padding: 0 2.625rem;
        position: relative;
        text-align: center;
    }

    .close {
        position: absolute;
        top: 1rem;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--purple-lightest);

        @media (min-width: $tablet) {
            top: 2.625rem;
            right: 2.625rem;
            left: auto;
            transform: none;
        }
    }
}

.overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 900;
    opacity: 0.98;
    background-color: var(--gray-light);
}

.scrollDownButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    position: absolute;
    bottom: 1rem;
    left: 50%;
    margin-left: -1.5rem;
    color: var(--white);
    cursor: pointer;
    background-color: var(--primary);
    -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
    animation: heartbeat 1.5s ease-in-out infinite both;

    svg {
        width: 1.25rem;
        transform: rotate(90deg);
    }
}
