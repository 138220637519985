@keyframes rotate {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

.wrapper {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 1.125rem;
    color: var(--primary);
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }

    .icon {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
    }

    &.isLoading {
        pointer-events: none;
        text-decoration: none;

        .icon {
            animation: rotate 1.5s linear infinite;
        }
    }
}
