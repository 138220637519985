@import '../../styles/variables';

.wrapper {
    display: inline-block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 0 1.5rem;
    font-size: 1.125rem;
    position: relative;

    @media (min-width: $tablet) {
        margin-bottom: 1.625rem;
    }

    p + p {
        margin-top: 0.75rem;
    }

    .description {
        margin-top: 0.5rem;
        color: var(--purple-dark);

        @media (min-width: $tablet) {
            margin-top: 1.625rem;
        }
    }

    .tooltip {
        display: inline-block;
        margin-left: 0.5rem;
    }
}
