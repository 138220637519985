@import '../../styles/variables.scss';

.wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    height: 3.8rem;
    padding: 0 1.25rem;
    border: 2px solid transparent;
    border-radius: 999px;
    font-weight: 700;
    line-height: 1;
    white-space: nowrap;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    transition: 500ms all;

    @media (min-width: $tablet) {
        height: 4.3rem;
        padding: 0 4rem;
    }

    &.primary {
        border-color: var(--primary);
        color: var(--white);
        background-color: var(--primary);

        &:hover {
            color: var(--primary);
            background-color: transparent;
        }

        &.outlined {
            color: var(--primary);

            &:hover {
                color: var(--white);
                background-color: var(--primary);
            }
        }

        &.active {
            color: var(--white);
            background-color: var(--primary);
            pointer-events: none;
        }
    }

    &.secondary {
        border-color: var(--orange);
        color: var(--gray-light);
        background-color: var(--orange);

        &:hover {
            color: var(--orange);
            background-color: transparent;
        }

        &.outlined {
            color: var(--orange);

            &:hover {
                color: var(--gray-light);
                background-color: var(--orange);
            }
        }
    }

    &.tertiary {
        border-color: var(--purple-dark);
        color: var(--white);
        background-color: var(--purple-dark);
        
        &.outlined {
            color: var(--purple-dark);
            
            &:hover {
                color: var(--white);
                background-color: var(--purple-dark);
            }
        }
    }
    
    &.white {
        color: var(--primary);
        background-color: var(--white);
            &:hover {
                border-color: var(--white);
                color: var(--white);
                background-color: transparent;
            }
    }

    &.outlined {
        background-color: transparent;
    }

    &.small {
        height: 2.35rem;
        padding: 0 0.75rem;

        @media (min-width: $tablet) {
            height: 3.0147058824rem;
            padding: 0 1.5rem;
        }
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .loader {
        display: none;
    }

    &.loading {
        pointer-events: none;

        .content {
            visibility: hidden;
        }

        .loader {
            display: block;
            position: absolute;
        }
    }

    &.link {
        background-color: transparent;
        text-decoration: underline;
        font-weight: normal;
    }
}
