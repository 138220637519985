@import '../../../styles/variables.scss';

.wrapper {
    .select {
        margin: 0 auto;
        max-width: 20rem;
        min-height: 2.75rem;
    }

    .resetProcessLink {
        cursor: pointer;
    }
}

.boxValue {
    width: 100%;
    padding: 0.6rem 1.5rem;
    max-width: 20rem;
    min-height: 2.75rem;
    margin: 0 auto;
    border: 2px solid var(--gray-medium);
    border-radius: 999px;
    font-weight: 600;
    font-size: 1.125rem;
    color: var(--gray-medium);
    background-color: var(--gray-light);
}

.boxValueWider {
    max-width: 45rem;
}

.help {
    margin-left: 0 !important;
}

.toggleForm {
    display: flex;
    justify-content: center;
    cursor: pointer;

    .text {
        align-items: center;
        display: flex;
        text-decoration: underline;
        text-align: left;
        line-height: 1.625;
    }

    &:hover .text {
        text-decoration: none;
    }
}

.label {
    padding: 0.25rem 1rem;
    justify-content: center;
    vertical-align: middle;
}
