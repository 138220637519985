@import '../../../styles/variables.scss';

.wrapper {
    .toggleTopButtons {
        width: auto;
    }

    .select {
        max-width: 25rem;
    }
}

.addMore {
    margin: auto;
}

.amountButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    width: 3rem;
}
