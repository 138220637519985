@import '../../styles/variables.scss';

.wrapper {
    .label {
        display: flex;
        width: 5rem;
        height: 5rem;
        padding: 1rem;
        border: 2px dashed transparent;
        border-radius: 0.5rem;
        cursor: pointer;

        @media (min-width: $desktop) {
            width: 10rem;
            height: 10rem;
            padding: 2rem;
        }

        &.good {
            color: #59cc14;
        }

        &.neutral {
            color: #fecb00;
        }

        &.bad {
            color: #e70e0e;
        }
    }

    .icon {
        width: 100%;
        height: 100%;
    }

    .checkbox {
        position: absolute;
        margin: 0;
        width: 0;
        height: 0;
        overflow: hidden;
        visibility: hidden;

        &:checked + .label {
            border-color: currentColor;
        }
    }
}
